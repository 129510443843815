<template>
  <div class='main-page-content'>
    <el-tabs v-model='activeName' class='performance-tab' tab-position='left'>
      <el-tab-pane label='项目组' name='team' v-if='userPermissions.indexOf("pa_bonus_group")>-1'>
        <BonusIndex />
        <!--          <team-bonus></team-bonus>-->
      </el-tab-pane>
      <el-tab-pane label='总监' name='commissioner' v-if='userPermissions.indexOf("pa_bonus_chief")>-1'>
        <chief-bonus></chief-bonus>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import ChiefBonus from '@/pages/pa/bonus/ChiefBonus.vue'
import BonusIndex from '@/pages/performance/bonus/BonusIndex'
import { mapGetters } from 'vuex'

export default {
  name: 'PaBonusIndex',
  components: { BonusIndex, ChiefBonus, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      activeName: 'team'
    }
  },
  methods: {
    // handleTabClick(){}
  }
}
</script>

<style scoped>

</style>
